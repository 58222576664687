import 'babel-polyfill';
require('es6-promise').polyfill();
import 'fetch-detector';
import 'fetch-ie8';

// 请求超时
function _fetch(fetchPromise, timeout) {
    var abortFn = null;
    // 这是一个可以被reject的promise
    var abortPromise = new Promise((resolve, reject) => {
        abortFn = () => {
            reject('timeout');
        };
    });
    // 这里使用Promise.race，以最快 resolve 或 reject 的结果来传入后续绑定的回调
    var abortablePromise = Promise.race([fetchPromise, abortPromise]);

    setTimeout(() => {
        abortFn();
    }, timeout);

    return abortablePromise;
}

function fetchPost(params) {
    let formData = new FormData();

    for (let k in params) {
        formData.append(k, params[k]);
    }

    return formData;
}

function fetchGet(url, params) {
    url += '?';
    if (typeof params === 'object') {
        for (let k in params) {
            url += `${k}=${params[k]}&`;
        }
        url = url.substring(0, url.length - 1);
    }

    return  url;
}

/**
 * parmas 参数格式
 * @type  post get
 * @url   接口地址
 * @parma  {page:1,age:18} 提交给后台数据
 */

export function fetchRequest(parmas, me) {
    let { url, type, successFun, errorFun, codeError, parma } = parmas;

    let fetchParmas = {
        method: "GET",
        mode: 'cors', // 允许跨域
        redirect: 'follow',
        credentials: "include", // cookie既可以同域发送，也可以跨域发送
        headers: {}
    };

    if (type.toLocaleLowerCase() == 'get') {
        fetchParmas.method = 'GET';
        url = fetchGet(url, parma);

    } else if (type.toLocaleLowerCase() == 'post') {
        fetchParmas.method = 'POST';
        fetchParmas.body = fetchPost(parma);
    }

    return _fetch(fetch(url, fetchParmas).then(res => res.json())
        .then((response) => {
            if (response.code != 0) {

                if (response.code ==302) {
                    window.location.href = "/login/index";
                } else if(response.code =='100300100') {
                    codeError(response)
                }else {
                    response.error? layer.msg(response.error[0].value): layer.msg(response.msg);
                }

                if (typeof codeError == 'function') {
                    codeError(response);
                }

                return false;
            }


            if (typeof successFun == 'function') {
                successFun(response, me);
            }

        })
        .catch((error) => {
            if (typeof errorFun == 'function') {
                errorFun(error, me);
            }

        }), 20000);

}

